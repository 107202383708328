import React from "react";

const TableControlBar = () => {
    const pokeCards = document.querySelectorAll(".card");
    return (
        // TODO: Does not always update with the right number
        // <React.Fragment>
        //     Displaying {pokeCards.length} of {pokeCards.length} Pokemon
        // </React.Fragment>
        <></>
    );
};

export default TableControlBar;
